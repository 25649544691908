import React,{useState} from 'react'
import * as Faicons from 'react-icons/all'

export const ReportData=[
  {
      name:'تقرير الانتاج',
      icon:< Faicons.FaProductHunt/>,
      cName:'nav-text-rep',
      path:'/TotalProd',
      handle:()=>console.log("تقرير الانتاج")
  },
  {
    name:'انتاج اليوم',
    icon:< Faicons.GoReport/>,
    cName:'nav-text-rep',
    path:'/dailyproduct',
    handle:()=>console.log("تقرير اليومي")
},
  {
    name:'تقرير التجديدات',
    icon:< Faicons.AiTwotoneInsurance/>,
    cName:'nav-text-rep',
    path:'/reneual',
    handle:()=>console.log("تقرير التجديدات")
},
  {
    name:' تقرير جميع الشيكات',
    icon:< Faicons.BiCheckShield/>,
    cName:'nav-text-rep',
    path:'/allchecks',
},

{
    name:'الشيكات الاجلة',
    path:'/acc',
    icon:< Faicons.BiCheckboxMinus/>,
    cName:'nav-text-rep',
    path:'/nextchecks',
},
{
    name:'الشيكات الراجعة',
    path:'/report',
    icon:< Faicons.IoReturnDownBack/>,
    cName:'nav-text-rep',
   // path:'/returnchecks',
},
{
  name:'كشف الزبائن',
  path:'/customers',
  icon:< Faicons.FcCustomerSupport/>,
  cName:'nav-text-rep',
 // path:'/returnchecks',
},
{
    name:' المصروفات',
    icon:< Faicons.FaCoins/>,
    cName:'nav-text-rep',
    path:'/expensesrep',
},
{
  name:' ميزان المراجعة',
  icon:< Faicons.FaBalanceScale/>,
  cName:'nav-text-rep',
 // path:'/cuns',
},

]