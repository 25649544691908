import React, {useMemo,useState,useEffect} from 'react'
import axios from "axios";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Button } from 'react-bootstrap';
import {Link,NavLink,useHistory } from 'react-router-dom'
import '../Style/PolPage2.css'
import moment from 'moment'
import excle from '../Images/excle.png'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const lurl="http://localhost:3001";
const url="https://alwafi.thesmartlogic.com";
function patchFilterFactory(filterFactory, onFilteredData) {
  return (...args) => {
     const {
        createContext,
        options
     } = filterFactory(...args)
     return {
        createContext: (...args) => {
           const {
              Provider: BaseProvider,
              Consumer
           } = createContext(...args)
           const Provider = class FilterProvider extends BaseProvider {
              componentDidUpdate() {
                 onFilteredData(this.data)
              }
           }
           return {
              Provider,
              Consumer
           }
        },
        options
     }
  }
}
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 10,
  lastPageText: '>>',
  firstPageText: '<<',
  nextPageText: '>',
  prePageText: '<',
  showTotal: true,
  alwaysShowAllBtns: true,
  onPageChange: function (page, sizePerPage) {
    console.log('page', page);
    console.log('sizePerPage', sizePerPage);
  },
  onSizePerPageChange: function (page, sizePerPage) {
    console.log('page', page);
    console.log('sizePerPage', sizePerPage);
  }
});






const DailyProd = () => {
  const [data, setData] = useState([]);
  const [pol, setPol] = useState([]);
  const [databyid, setDatabyid] = useState([]);
  let history = useHistory ();

  const columns = [
   
    {
      dataField: "pol_no",
      text: "رقم الوثيقة",
      sort: true,
     /*{filter: textFilter({
        delay: 0,
      })
   } */
    },
   
    {
      dataField: "pol_year",
     /* filter: textFilter({
        delay: 0
      }),*/
      text: "السنة",
      sort: true,
    
    },
    {
      dataField: "ins_comp",
     /* filter: textFilter({
        delay: 0
      }),*/
      text: "شركة التأمين",
      sort: true,
    
    },
   
    {
      dataField: "acc_type",
      text: "نوع الحساب",
      sort: true,
      
    },
    {
      dataField: "cust_acc",
      text: " حساب الزبون",
      sort: true,
      
    },
    {
      dataField: "comp_name",
      text: " اسم الشركة",
      sort: true,
      
    },
    {
      dataField: "cust_name",
      text: "اسم الزبون",
      sort: true,
      
    },
    {
        dataField:"reg_dt",
        text: "تاريخ التسجيل",
        sort: true,
        rowspan: 2,
        align: 'center',
        valign: 'middle',
        sortable: true,
        
      },
    {
      dataField: "maj_ins",
      text: "التأمين الرئيسي",
      sort: true,
      
    },
    {
      dataField: "min_ins",
      text: "التأمين الفرعي",
      sort: true,
      
    },
    
  
  ]
  




    
useEffect(() => {
  (async () => {
    const result = await axios(`${url}/dailyprod`);
    setData(result.data);
   
  })();
}, []);





const factory = patchFilterFactory(filterFactory, (filteredData) => {
 
})

  return (
  
    <div className="App">
      <BootstrapTable className="table table-striped"
       filter={factory() }
        bootstrap4
        keyField="id"
        data={data}
        columns={columns}
        pagination={pagination}
        hover={true} search={true} 
        
      
      />
       <ExcelFile  element={<><button><img className='excle' src={excle}/></button> <card>استخراج البيانات في ملف اكسيل</card></>}>
                <ExcelSheet data={data} name="الانتاج اليومي">
                    <ExcelColumn label="pol_no" value="pol_no"/>
                    <ExcelColumn label="pol_year" value="pol_year"/>
                    <ExcelColumn label="ins_comp" value="ins_comp"/>
                    <ExcelColumn label="acc_type" value="acc_type"/>
                    <ExcelColumn label="cust_acc" value="cust_acc"/>
                    <ExcelColumn label="cust_name" value="cust_name"/>
                    <ExcelColumn label="comp_name" value="comp_name"/>
                    <ExcelColumn label="car_type" value="car_type"/>
                    <ExcelColumn label="teraz_no" value="teraz_no"/>
                    <ExcelColumn label="prem" value="prem"/>
                    <ExcelColumn label="total_prem" value="total_prem"/>
                    <ExcelColumn label="cust_jaw" value="cust_jaw"/>
                    
                </ExcelSheet>
               
            </ExcelFile>

    </div>
    
  );
};

export default DailyProd