import React, {useMemo,useState,useEffect} from 'react'
import axios from "axios";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Button ,Col,Form,Row} from 'react-bootstrap';
import {Link,NavLink,useHistory } from 'react-router-dom'
import '../Style/Rennual.css'
import {FormControl,TextField} from '@mui/material';
import moment from 'moment'
import excle from '../Images/excle.png'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const lurl="http://localhost:3001";
const url="https://alwafi.thesmartlogic.com";


function patchFilterFactory(filterFactory, onFilteredData) {
  return (...args) => {
     const {
        createContext,
        options
     } = filterFactory(...args)
     return {
        createContext: (...args) => {
           const {
              Provider: BaseProvider,
              Consumer
           } = createContext(...args)
           const Provider = class FilterProvider extends BaseProvider {
              componentDidUpdate() {
                 onFilteredData(this.data)
              }
           }
           return {
              Provider,
              Consumer
           }
        },
        options
     }
  }
}
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 10,
  lastPageText: '>>',
  firstPageText: '<<',
  nextPageText: '>',
  prePageText: '<',
  showTotal: true,
  alwaysShowAllBtns: true,
  onPageChange: function (page, sizePerPage) {
    console.log('page', page);
    console.log('sizePerPage', sizePerPage);
  },
  onSizePerPageChange: function (page, sizePerPage) {
    console.log('page', page);
    console.log('sizePerPage', sizePerPage);
  }
});






const Rennual = () => {
  const [data, setData] = useState([]);
  const [pol, setPol] = useState([]);
  const [databyid, setDatabyid] = useState([]);
  const [date1,setDate1]=useState()
  const [date2,setDate2]=useState()
  let history = useHistory ();

  const columns = [
    {
      dataField: "id",
      text: "الرقم",
      sort: true,
     /*{filter: textFilter({
        delay: 0,
      })
   } */
    },
    {
      dataField: "pol_no",
      text: "رقم الوثيقة",
      sort: true,
     /*{filter: textFilter({
        delay: 0,
      })
   } */
    },
   
    {
      dataField: "pol_year",
     /* filter: textFilter({
        delay: 0
      }),*/
      text: "السنة",
      sort: true,
    
    },
    {
      dataField: "ins_comp",
     /* filter: textFilter({
        delay: 0
      }),*/
      text: "شركة التأمين",
      sort: true,
    
    },
   
    {
      dataField: "comp_name",
      text: "اسم الشركة",
      sort: true,
      
    },
    {
      dataField: "cust_acc",
      text: " رقم الحساب",
      sort: true,
      
    },
    {
      dataField: "plate_no",
      text: " رقم اللوحة",
      sort: true,
      
    },
    {
      dataField: "cust_name",
      text: "اسم الزبون",
      sort: true,
      
    },
    {
      dataField:"start_dt",
      text: " بداية التأمين",
      sort: true,
      rowspan: 2,
      align: 'center',
      valign: 'middle',
      sortable: true,
      
    },
    {
        dataField:"end_dt",
        text: " نهاية التأمين",
        sort: true,
        rowspan: 2,
        align: 'center',
        valign: 'middle',
        sortable: true,
        
      },
    {
      dataField: "maj_ins",
      text: "التأمين الرئيسي",
      sort: true,
      
    },
    {
      dataField: "min_ins",
      text: "التأمين الفرعي",
      sort: true,
      
    },
    
  
  ]
  




    
const getrennual=async() => {
  
    const result = await axios.get(`${url}/rennual/${date1}/${date2}`);
    setData(result.data);
}





const factory = patchFilterFactory(filterFactory, (filteredData) => {
 
})

  return (
  
    <div className="App">
      {console.log(date1)}
       <Form className="">
       <Row className="row1">
        <FormControl as={Col} id="outlined-basic">
          <TextField size="small" helperText=" من تاريخ" variant="outlined"  type="date" label=" من تاريخ"  onChange={(e)=>{setDate1(e.target.value)}}  />
           </FormControl>
           <FormControl as={Col} id="outlined-basic">
           <TextField size='small'  helperText=" الى تاريخ"   variant="outlined" type="date"  label=" الى تاريخ"  onChange={(e)=>{setDate2(e.target.value)}} />
            </FormControl>
            <FormControl as={Col} id="outlined-basic">
            <Button size='small' onClick={getrennual}>بحث</Button>
            </FormControl>
            </Row>
        </Form>
      <BootstrapTable className="table table-striped"
       filter={factory() }
        bootstrap4
        keyField="id"
        data={data}
        columns={columns}
        pagination={pagination}
        hover={true} search={true} 
        
      
      />
    <ExcelFile  element={<><button><img className='excle' src={excle}/></button> <card>استخراج البيانات في ملف اكسيل</card></>}>
                <ExcelSheet data={data} name=" التجديدات">
                    <ExcelColumn label="pol_no" value="pol_no"/>
                    <ExcelColumn label="pol_year" value="pol_year"/>
                    <ExcelColumn label="start_date" value="start_dt"/>
                    <ExcelColumn label="end_date" value="end_dt"/>
                    <ExcelColumn label="comp_name" value="comp_name"/>
                    <ExcelColumn label="plate_no" value="plate_no"/>
                    <ExcelColumn label="cust_name" value="cust_name"/>
                    <ExcelColumn label="car_type" value="car_type"/>
                    <ExcelColumn label="teraz_no" value="teraz_no"/>
                    <ExcelColumn label="prem" value="prem"/>
                    <ExcelColumn label="Final_prem" value="total_prem"/>
                    <ExcelColumn label="cust_jaw" value="cust_jaw"/>

                    
                </ExcelSheet>
               
            </ExcelFile>
    </div>
    
  );
};

export default Rennual