import React,{useState} from 'react'
import * as Faicons from 'react-icons/all'

export const SidebarData=[
  {
      name:'الرئيسية',
      path:'/',
      icon:< Faicons.AiFillHome/>,
      cName:'nav-text'
  },
  {
    name:'الأعيان المؤمنة',
    path:"/sco",
    icon:< Faicons.IoIosPaper/>,
    cName:'nav-text'
},

{
    name:'المحاسبة',
    path:'/acc',
    icon:< Faicons.FaCalculator/>,
    cName:'nav-text'
},
{
    name:'التقارير',
    path:'/report',
    icon:< Faicons.HiDocumentReport/>,
    cName:'nav-text'
},
{
    name:'احصائيات',
    path:'/static',
    icon:< Faicons.GoRequestChanges/>,
    cName:'nav-text'
},
{
    name:'المصروفات',
    path:'/expenses',
    icon:< Faicons.SiExpensify/>,
    cName:'nav-text'
},


]
