
import React, { useState } from "react";
import { useTable, useFilters, useSortBy,usePagination } from "react-table";
import '../Style/RepTable.css'

function RepTable({ columns, data }) {
  const [filterInput, setFilterInput] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setFilter,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 1 },
    },
    useFilters,useSortBy,usePagination
  );
  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("title", value);
    setFilterInput(value);
  };
  // Render the UI for your table
  return (
    <div className="tablerep">
      <input className="search"
        value={filterInput}
        onChange={handleFilterChange}
        placeholder={"بحث من خلال الاسم"}
      />
      <table {...getTableProps()}>
      <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
     
      <nav aria-label="...">
  <ul class="pagination">
    <li class="page-item " onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
      <a class="page-link"  tabindex="-1">الاولى</a>
    </li>
    <li class="page-item " aria-current="page" onClick={() => previousPage()} disabled={!canPreviousPage}>
      <a class="page-link"> {'<'} </a>
    </li>
    <li class="page-item "  onClick={() => nextPage()} disabled={!canNextPage}>
      <a class="page-link">{'>'}</a>
    </li>
    <li class="page-item">
      <a class="page-link"  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} >الاخيرة</a>
    </li>  
    <li>
   
        <span className="page-item">
         صفحة رقم{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[5,10, 20, 30, 40, 50,100,200].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              اظهار {pageSize}
            </option>
          ))}
        </select>
    </li>
  </ul>
  
</nav>

        

   
    </div>
  )
}

export default RepTable
