import React,{useState} from 'react'
import * as Faicons from 'react-icons/all'
import {AiOutlineLink} from 'react-icons/ai'
import {Link,NavLink} from 'react-router-dom'
import {SidebarData} from './SidebarData'
import '../Style/Sidebar.css'
import navlogo from '../Images/navlogo.png'
import { NavDropdown, MenuItem } from "react-bootstrap";


function Sidebar() {
    const [navbar,setNavbar]=useState(true)

    return (
        <>
            
         <nav className={navbar ?'nav-menu active' : 'nave-menu' }>
         <h3 style={{color:"white",fontFamily:"cursive"}} className=" shadow-0  text-center   "> بوابة المسافر للتأمين
            <hr/>
           
               </h3>
             <ul className="nav-menu-items">
            
                    {SidebarData.map((item,index)=>{
                        return(
                            <li key={index} className={item.cName}>
                              <Link to={item.path}>
                                 <span className={'icon'}>{item.icon}</span> 
                                  <span>{item.name}</span>
                              </Link>
                            </li>
                        )
                    })}
                    <li >
             <li class="nav-text icon"><span className={'icon'}>{<AiOutlineLink/>}</span> <a href="http://pic-sys.pic-pal.ps/auth/login?ReturnUrl=%2FIdentity%2FPCMA%2FVehicleLicense%3Fid%3D0%26fbclid%3DIwAR0tFQgAVAR4IzLzpLKkSyRXB2LByb6PhkO0h61UxuyQAvaQ7xCPtpRNw9c" target="_blank">نظام الموحد</a></li>
                             
                            </li>
                   
             </ul>
         </nav>
        </>
    )
}

export default Sidebar
