import React from 'react'
import '../Style/RepPage.css'
import { ReportData } from '../Components/ReportData'
import {Link,NavLink} from 'react-router-dom'
import { Form, Button,ButtonGroup } from "react-bootstrap";
const ReportMenue = () => {
    return (
        <div className='main'>

        {ReportData.map((item,index)=>{
                        return(
                            <li key={index} className={item.cName}>
                                <Link to={item.path}>
                            <Button style={{background:'white',marginTop:'3rem',padding:"0px",border:'0 white',}} onClick={item.handle}> 
                               
                               <div  class="card" style={{height:'11rem',width:'12rem',background:'white',marginTop:'0rem',justifyContent:'center'}}>
                              <span className={'repicon'}>{item.icon}</span> 
                              <p className={'item'}>{item.name}</p>
                               </div>
                              
                             </Button>
                             </Link>
                            </li>
                            )})}


                   
                    </div>
    )
}

export default ReportMenue
