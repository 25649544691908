import * as Faicons from 'react-icons/all'
export const StaticData=[
    {
        name:'عدد الوثائق',
        icon:< Faicons.SiRxdb/>,
        cName:'nav-text-rep',
        handle:()=>console.log("تقرير الانتاج")
    },
    {
      name:'المصدر اليوم',
      icon:< Faicons.MdOutlineCountertops/>,
      cName:'nav-text-rep',
      handle:()=>console.log("تقرير اليومي")
  },
    {
      name:'عدد الشيكات المقبوضة',
      icon:< Faicons.BiReceipt/>,
      cName:'nav-text-rep',
      handle:()=>console.log("تقرير التجديدات")
  },
    {
      name:'عدد الشيكات الراجعة',
      icon:< Faicons.IoReturnUpBackOutline/>,
      cName:'nav-text-rep',
    
  },
  
  {
      name:'تأمينات السيارات',
      icon:< Faicons.IoCarSport/>,
      cName:'nav-text-rep',
  },
  {
      name:'التأمينات العامة',
      icon:< Faicons.BiHomeCircle/>,
      cName:'nav-text-rep',
  },
  {
    name:'حركات القيد المرتجع',
    icon:< Faicons.BiHomeCircle/>,
    cName:'nav-text-rep',
},
{
  name:'المقبوض اليوم',
  icon:< Faicons.BsCashCoin/>,
  cName:'nav-text-rep',
},
{
  name:' المبلغ الكلي للمقبوض كاش',
  icon:< Faicons.BsCashCoin/>,
  cName:'nav-text-rep',
},
{
  name:'المبلغ الكلي للمقبوض شيكات',
  icon:< Faicons.BsCashCoin/>,
  cName:'nav-text-rep',
},
  
  
  ]