import React, {useMemo,useState,useEffect} from 'react'
import axios from "axios";
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
const lurl="http://localhost:3001";
const url="https://alwafi.thesmartlogic.com";




export const Totalpolnotif=()=>{
    const [totaldoc,setTotaldoc]=useState([])
    useEffect(() => {
        (async () => {
            axios.get(`${url}/totalinscounts`)
            .then((response) => {
                setTotaldoc(response.data);
             console.log(response.data)
                        });
          })();
      }, []);

      return (
        <>
        {totaldoc.map((item,index)=>{
                        return(
        <Stack spacing={8} direction="row" sx={{ color: 'action.active',size:'larg' }}>
          <Badge color="secondary" badgeContent={item.c}
           anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
           
          }}
          >
            <MailIcon />
          </Badge>
        </Stack>
        )})}
        </>
      )
    
}

export const Renualnotif=()=>{
    const [totaldoc,setTotaldoc]=useState([])
    useEffect(() => {
        (async () => {
            axios.get(`${url}/willendtoday`)
            .then((response) => {
                setTotaldoc(response.data);
             console.log(response.data)
                        });
          })();
      }, []);

      return (
        <>
        {totaldoc.map((item,index)=>{
                        return(
        <Stack spacing={4} direction="row" sx={{ color: 'action.active' }}>
          <Badge color="warning" badgeContent={item.c} showZero
           anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          >
            <HistoryRoundedIcon />
          </Badge>
        </Stack>
        )})}
        </>
      )
    
}

