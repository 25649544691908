import React, {useMemo,useState,useEffect} from 'react'
import axios from "axios";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Button ,Col,Form,Row} from 'react-bootstrap';
import {Link,NavLink,useHistory } from 'react-router-dom'
import {FormControl,TextField} from '@mui/material';
import moment from 'moment'
import excle from '../Images/excle.png'
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const lurl="http://localhost:3001";
const url="https://alwafi.thesmartlogic.com";
function patchFilterFactory(filterFactory, onFilteredData) {
  return (...args) => {
     const {
        createContext,
        options
     } = filterFactory(...args)
     return {
        createContext: (...args) => {
           const {
              Provider: BaseProvider,
              Consumer
           } = createContext(...args)
           const Provider = class FilterProvider extends BaseProvider {
              componentDidUpdate() {
                 onFilteredData(this.data)
              }
           }
           return {
              Provider,
              Consumer
           }
        },
        options
     }
  }
}
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 10,
  lastPageText: '>>',
  firstPageText: '<<',
  nextPageText: '>',
  prePageText: '<',
  showTotal: true,
  alwaysShowAllBtns: true,
  onPageChange: function (page, sizePerPage) {
    console.log('page', page);
    console.log('sizePerPage', sizePerPage);
  },
  onSizePerPageChange: function (page, sizePerPage) {
    console.log('page', page);
    console.log('sizePerPage', sizePerPage);
  }
});






const Allcheqs = () => {
  const [data, setData] = useState([]);
  const [pol, setPol] = useState([]);
  const [databyid, setDatabyid] = useState([]);
  const [date1,setDate1]=useState()
  const [date2,setDate2]=useState()
  let history = useHistory ();

  const columns = [
    {
      dataField: "id",
      text: "الرقم",
      sort: true,
     /*{filter: textFilter({
        delay: 0,
      })
   } */
    },
    {
      dataField: "polno",
      text: "رقم الوثيقة",
      sort: true,
     /*{filter: textFilter({
        delay: 0,
      })
   } */
    },
   
    {
      dataField: "pol_year",
     /* filter: textFilter({
        delay: 0
      }),*/
      text: "السنة",
      sort: true,
    
    },
    {
      dataField: "total_prem",
     /* filter: textFilter({
        delay: 0
      }),*/
      text: "القسط",
      sort: true,
    
    },
   
    {
      dataField: "cash",
      text: "دفعة كاش",
      sort: true,
      
    },
    {
      dataField: "cust_acc",
      text: " حساب الزبون",
      sort: true,
      
    },
    {
      dataField: "cust_name",
      text: "اسم الزبون",
      sort: true,
      
    },
    {
      dataField:"bank",
      text: "البنك",
      sort: true,
      
    },
    {
        dataField:"chq_no",
        text: "رقم الشيك",
        sort: true,
        
      },
    {
      dataField: "chq_owner",
      text: "صاحب الشيك",
      sort: true,
      
    },
    {
      dataField: "chq_value",
      text: "قيمة الشيك",
      sort: true,
      
    },
    {
        dataField: "chq_date",
        text: "تاريخ الشيك",
        sort: true,
        rowspan: 2,
        align: 'center',
        valign: 'middle',
        sortable: true,
        
      },
    
  
  ]
  




    
  useEffect(() => {
    (async () => {
      const result = await axios(`${url}/allchqs`);
      setData(result.data);
     
    })();
  }, []);

const factory = patchFilterFactory(filterFactory, (filteredData) => {
 
})

  return (
  
    <div className="App">
      
      <BootstrapTable className="table table-striped"
       filter={factory() }
        bootstrap4
        keyField="id"
        data={data}
        columns={columns}
        pagination={pagination}
        hover={true} search={true} 
        
      
      />
  <ExcelFile  element={<><button><img className='excle' src={excle}/></button> <card>استخراج البيانات في ملف اكسيل</card></>}>
                <ExcelSheet data={data} name="جميع الشيكات">
                    <ExcelColumn label="pol_no" value="polno"/>
                    <ExcelColumn label="pol_year" value="pol_year"/>
                    <ExcelColumn label="bank" value="bank"/>
                    <ExcelColumn label="chqno" value="chq_no"/>
                    <ExcelColumn label="chq_owner" value="chq_owner"/>
                    <ExcelColumn label="chq_value" value="chq_value"/>
                    <ExcelColumn label="Total prem" value="total_prem"/>
                    <ExcelColumn label="cust_name" value="cust_name"/>
                    <ExcelColumn label="chq_date" value="chq_date"/>
                    <ExcelColumn label="cash" value="cash"/>
                </ExcelSheet>
               
            </ExcelFile>
    </div>
    
  );
};

export default Allcheqs