import React,{useState} from 'react'
import axios from 'axios';
import { setUserSession } from './Common';
import "bootstrap/dist/css/bootstrap.css"
import { Form, Button,Container,Row,Col, FormGroup, FormCheck, InputGroup,Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUnlockAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import BG from '../Images/bg.jpg'
import { useHistory  } from "react-router-dom";
import '../Style/Login.css'



const lurl="http://localhost:3001";
const url="https://alwafi.thesmartlogic.com";
const Login=(props)=>{
 const [username,setUsername]=useState('')
 const [password,setPassword]=useState('')
 const [error,setError]=useState()
 const [loading,setLoading]=useState(false)

 const history = useHistory()
 
 const handleLogin = () => {
  setError(null);
  setLoading(true);
  axios.post(`${url}/signin`, { username: username, password: password }).then(response => {
    setLoading(false);
    setUserSession(response.data.token, response.data.user);
    history.push('/')
  }).catch(error => {
    setLoading(false);
    if (error.response.status === 401) setError(error.response.data.message);
    else setError("يوجد خلل ما,حاول مرة اخرى");
  });
}

    return (
        <> 
        <main>
          <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5"></section>
       <Container className="Container shadow-lg">
                <h1 style={{border:"1px solid white",color:"#004960"}} className="  shadow-sm  mt-2 p-2 text-center   "> WAFI-   نظام الوافي لاكتتاب التأمين</h1>
                <Row className="mt-1">
                
                    <Col lg={5} md={8} sm={12} className="p-2 m-auto  rounded-lg d-flex align-items-center justify-content-center rounded">
                    <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                        <div className="text-center text-md-center mb-12 mt-md-0">
                        {error && <div className='error alert alert-danger'>{error}</div>}
                        <h3 className="mb-0"> الدخول الى النظام </h3>
                        </div>
                        
                     <Form className="mt-4">
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>اسم المستخدم</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUserCircle} />
                          </InputGroup.Text>
                          <Form.Control autoFocus required type="text" placeholder="example@company.com" 
                          value={username}
                          onChange={e=>setUsername(e.target.value)}
                          />
                        </InputGroup>
                     
                        <Form.Group id="password" className="mb-4">
                          <Form.Label>كلمة المرور</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control required type="password" placeholder="Password"
                            value={password}
                            onChange={e=>setPassword(e.target.value)}
                            
                            />
                          </InputGroup>
                        </Form.Group>
                     
                      </Form.Group>
                      <Button value={loading ? "Loading ..." :"Login"} disabled={loading} variant="primary" type="submit" className="w-100"
                      onClick={handleLogin}
                      >تسجيل الدخول
                      </Button>
                     
                     
                   
                    </Form>
                   
                  </div>
                </Col>

                    <Col className="p-3 m-auto  rounded-lg d-flex align-items-center justify-content-center rounded" >
                       <Image src={BG} thumbnail style={{border:"none"}}></Image>
                    </Col>
                </Row>
              
            </Container>
            </main>
        </>
    )
}
export default Login 